<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="SMS Tabletop ABL Friction Machine" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2"></div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            Safety Management Services, Inc. (SMS™) manufactures an SMS Tabletop
            ABL Friction Test Machine as an economical alternative to the SMS
            ABL Friction Test Machine. Like the SMS ABL Friction Test Machine,
            the SMS Tabletop ABL Friction Test Machine also provides in-house
            testing to determine the energy required to initiate a sample by
            friction energy application.<br />
            The SMS Tabletop ABL Friction Test Machine reduces cost and size by
            eliminating the low speed drive used when testing extremely
            sensitive materials. The majority of tests that are run using the
            ABL Friction Test Machine do not require the use of the low speed
            drive because the sensitivity level can be found at the velocities
            created by the pendulum. The smaller size of the test apparatus
            allows it to be mounted to any stable workbench or table.<br />
            The primary parts of the test apparatus are similar to the ABL
            Friction Test Machine and include a moving anvil, stationary wheel,
            and swinging pendulum. The test sample is placed on the moving anvil
            and the stationary wheel is lowered, using a hydraulic ram, until it
            pinches the sample against the moving anvil. A specified load,
            indicated by a digital readout on the main junction box, is applied
            to the sample by the stationary wheel. The swinging anvil is raised
            to one of four indicated heights and released, striking the moving
            anvil. The moving anvil travels a distance of approximately one
            inch, moving the sample underneath the stationary wheel. Depending
            on the height of the pendulum, the anvil moves at a calibrated
            maximum velocity of 3, 4, 6, or 8 ft/sec. After testing, engineering
            units for the sensitivity results are easily obtained.<br />
            Sample initiation is determined by production of smoke, fire or an
            audible pop. Gas detection by infrared sampling analysis is also
            possible. The SMS Tabletop ABL Friction Test Machine can be pared
            with GoDetect™ System to increase reaction detection efficiency.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "ABL Friction Test Machine",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS manufactures an SMS Tabletop ABL Friction Test Machine as an economical alternative to the SMS ABL Friction Test Machine. Like the SMS ABL Friction Test Machine, the SMS Tabletop ABL Friction Test Machine also provides in-house testing to determine the energy required to initiate a sample by friction energy application."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.about {
}
</style>
